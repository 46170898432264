import React from 'react';

import Layout from '../components/layout';

export default function Math() {
      return (
            <Layout subject="Math">
                  Math
            </Layout>
      )
}